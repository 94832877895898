import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './styles/font.css';
import AllRoutes from './routes/AllRoutes';
import Navbar from './components/Navbar/Navbar'; // Check the path to Navbar component
import Footer from './components/Navbar/Footer'; // Correct path to Footer component

const theme = extendTheme({
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0,
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <RoutesHandler />
      </Router>
    </ChakraProvider>
  );
}

const RoutesHandler = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === "/landing";

  return (
    <>
      {!isLandingPage && <Navbar />}
      <div>
        <AllRoutes />
      </div>
      {!isLandingPage && <Footer />}
    </>
  );
};

export default App;
