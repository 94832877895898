import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import img1 from "../../../assets/LandingPage/2.webp";
import img2 from "../../../assets/LandingPage/3.webp";
import img3 from "../../../assets/LandingPage/4.webp";
import img4 from "../../../assets/LandingPage/5.webp";
import custom from "./custom.css";

const RevenueSection = () => {
    return (
        <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["80%"]} margin={["0 auto"]}>
            <Box>
                <Text
                    color={["#1E1E1E"]}
                    fontSize={["26px", "44px"]}
                    fontFamily={["HouschkaPro-Demibold"]}
                    lineHeight={["normal"]}
                    width={["100%", "50%"]}
                    paddingBottom={["36px"]}
                >
                    <span style={{ color: '#01A6FB' }}>Sustainable growth</span> comes from remarkable content and its strategic distribution.
                </Text>

            </Box>
            <Box display={["flex"]} flexDirection={["column", "row"]} justifyContent={["space-between"]} paddingBottom={["36px"]}
            >
                <Box display="flex" alignItems="center">
                    <Box
                        width="117px"
                        height="2px" // Adjust the height as needed
                        backgroundColor="#000000" // Color of the line
                        marginRight="8px" // Space between line and text
                    />
                    <Text
                        color={["#000000"]}
                        fontSize={["20px", "24px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        paddingBottom={["15px", 0]}
                        textTransform={["capitalize"]}
                    >
                        Our Services
                    </Text>
                </Box>

                <Box width={["100%", "50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["20px", "24px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                    >
                        Your content will do more than just rank. It will deliver a level of style, depth, and value that turns readers into customers and customers into advocates.
                    </Text>
                </Box>
            </Box>
            <Box display={["flex"]} flexDirection={["column", "row"]} gap={["10px"]}>
                <Box display={["flex"]} flexDirection={["column"]} gap={["17px"]} flex="1">
                    <Box flex="1" display="flex" justifyContent="center" alignItems="center">
                        <img
                            src="https://d2elmmls1zw4az.cloudfront.net/girafe/landing/1.png"
                            style={{
                                maxHeight: '100%',
                                width: 'auto',
                                height: '100%',
                                transition: 'transform 0.5s ease',
                                transformOrigin: 'center center'
                            }}
                            className="zoom-image"
                        />
                    </Box>
                    <Box flex="1" display="flex" justifyContent="center" alignItems="center">
                        <img
                            src="https://d2elmmls1zw4az.cloudfront.net/girafe/landing/2.png"
                            style={{
                                maxHeight: '100%',
                                width: 'auto',
                                height: '100%',
                                transition: 'transform 0.5s ease',
                                transformOrigin: 'center center'
                            }}
                            className="zoom-image"
                        />
                    </Box>
                </Box>
                <Box flex="1" display="flex" justifyContent="center" alignItems="center">
                    <img
                        src={img4}
                        style={{
                            maxHeight: '100%',
                            width: 'auto',
                            height: '100%',
                            transition: 'transform 0.5s ease',
                            transformOrigin: 'center center'
                        }}
                        className="zoom-image"
                    />
                </Box>
                <Box flex="1" display="flex" justifyContent="center" alignItems="center">
                    <img
                        src="https://d2elmmls1zw4az.cloudfront.net/girafe/landing/4.png"
                        style={{
                            maxHeight: '100%',
                            width: 'auto',
                            height: '100%',
                            transition: 'transform 0.5s ease',
                            transformOrigin: 'center center'
                        }}
                        className="zoom-image"
                    />
                </Box>

            </Box>

        </Box>
    );
};
export default RevenueSection;