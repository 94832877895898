import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import img from "../../../assets/LandingPage/1.webp";

const RevenueSection = () => {
    return (
        <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["88%"]} margin={["0 auto"]}>
            <Box display={["flex"]} flexDirection={["column-reverse", "row"]} justifyContent={["space-between"]} backgroundColor={["#387AAA"]} borderRadius={["10px"]}>
                <Box display="flex" justifyContent={["center"]} width={["90%", "35%"]} flexDirection={["column"]} margin={["0 auto"]} paddingTop={["20px", 0]} paddingBottom={["20px", 0]}>
                    <Text
                        color={["#ffffff"]}
                        fontSize={["20px", "32px"]}
                        fontFamily={["HouschkaPro-Light"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                        paddingBottom={["30px"]}
                    >
                        E-commerce Websites
                    </Text>
                    <Text
                        color={["#ffffff"]}
                        fontSize={["20px", "45px"]}
                        fontFamily={["HouschkaPro-Bold"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                    >
                        75%
                    </Text>
                    <Text
                        color={["#ffffff"]}
                        fontSize={["20px", "20px"]}
                        fontFamily={["HouschkaPro-Light"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                    >
                        Increase of 4.3 million
                    </Text>
                    <Text
                        color={["#ffffff"]}
                        fontSize={["20px", "20px"]}
                        fontFamily={["HouschkaPro-Light"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                        paddingBottom={["20px"]}
                    >
                        impressions from Q3 to Q4
                    </Text>
                    <Text
                        color={["#ffffff"]}
                        fontSize={["20px", "20px"]}
                        fontFamily={["HouschkaPro-Light"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                    >
                        SEO continues to be the cornerstone of digital marketing for a reason. It not only drives sustained traffic and customer engagement, but also outperforms other marketing strategies in terms of ROI, making it a crucial investment for businesses looking to scale.
                    </Text>
                </Box>

                <Box width={["100%", "50%"]}>
                    <img src={img} />
                </Box>
            </Box>
        </Box>
    );
};
export default RevenueSection;