import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import HeroSection from "./DetailPage/HeroSection";
import RevenueSection from "./DetailPage/Revenue";
import Website from "./DetailPage/Website";
import Growth from "./DetailPage/Growth";
import Project from "./DetailPage/Project";
import Testimonials from "./DetailPage/Testimonials";
import Text from "./DetailPage/Text";
import Talk from "./DetailPage/Talk";

const Home = () => {
  return (
    <>
      <Box>
          <HeroSection />
      </Box>

      <Box>
        <RevenueSection />
      </Box>

      <Box>
        <Website />
      </Box>

      <Box>
        <Growth />
      </Box>

      <Box>
        <Project />
      </Box>

      <Box>
        <Testimonials />
      </Box>

      <Box>
        <Text />
      </Box>

      <Box>
        <Talk />
      </Box>

    </>
  );
};

export default Home;