import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import img from "../../../assets/LandingPage/7.webp";

const RevenueSection = () => {
    return (
        <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["80%"]} margin={["0 auto"]} paddingBottom={["40px", "80px"]}>
            <Box display={["flex"]} flexDirection={["column", "row"]} justifyContent={["space-between"]} borderRadius={["10px"]} gap={["35px"]}>

                <Box width={["100%", "50%"]}>
                    <img src={img} />
                </Box>

                <Box display="flex" justifyContent={["center"]} width={["55%"]} flexDirection={["column"]} margin={["0 auto"]}>
                    <Text
                        color={["#000"]}
                        fontSize={["20px", "42px"]}
                        fontFamily={["HouschkaPro-DemiBold"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                        width={["100%", "85%"]}
                        paddingBottom={["15px"]}
                    >
                        Drive more <span style={{ color: '#01A6FB' }}>organic traffic</span> to your website.
                    </Text>
                    <Text
                        color={["#000000"]}
                        fontSize={["20px", "16px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        textAlign={["left"]}
                        paddingBottom={["15px"]}
                    >
                        Get more qualified traffic on the search terms that matter most to your business. We achieve measurable results by working on every variable that impacts SERPs. Trust our proven track record to maximize your visibility online. Set your business up for long-term success.
                    </Text>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <Text
                            color="#1e1e1e"
                            fontSize={["20px", "60px"]}
                            fontFamily="HouschkaPro-DemiBold"
                            lineHeight="normal"
                            textAlign="left"
                            mr="20px" // margin-right to add space between the texts
                        >
                            200%
                        </Text>
                        <Text
                            color="#1e1e1e"
                            fontSize={["20px", "16px"]}
                            fontFamily="HouschkaPro-Medium"
                            lineHeight="normal"
                            textAlign="left"
                        >
                            Most of our SEO campaigns double <br />their traffic within 6 months
                        </Text>
                    </Box>

                </Box>
            </Box>
        </Box>
    );
};
export default RevenueSection;