import React, { useEffect, useRef } from 'react';
import { Box, Flex, Heading, Text, Image, IconButton } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper/modules';

// Import local images for the slides and navigation buttons
import img from '../../../assets/LandingPage/6.png';
import leftArrow from '../../../assets/LandingPage/left.svg';
import rightArrow from '../../../assets/LandingPage/right.svg';
import img2 from '../../../assets/LandingPage/icon.svg';

const SliderComponent = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const slides = [
        {
            image: img, // Local image for the first slide
            title: 'I loved our account team and Gabby was absolutely amazing. Directive helped us drive significant traffic growth over the last four years and has been a grade-A partner. Our decision to leave was primarily based on budget considerations (we had to reduce our agency budget) and, secondarily to get a fresh set of eyes on our strategy and tactics after a long engagement with Directive. You hear from me again and I highly recommend Directive to anyone who asks!',
            name: 'Oven Ray',
            company: 'Invoca',
        },
        {
            image: img, // Local image for the first slide
            title: 'I loved our account team and Gabby was absolutely amazing. Directive helped us drive significant traffic growth over the last four years and has been a grade-A partner. Our decision to leave was primarily based on budget considerations (we had to reduce our agency budget) and, secondarily to get a fresh set of eyes on our strategy and tactics after a long engagement with Directive. You hear from me again and I highly recommend Directive to anyone who asks!',
            name: 'Oven Ray',
            company: 'Invoca',
        },
        {
            image: img, // Local image for the first slide
            title: 'I loved our account team and Gabby was absolutely amazing. Directive helped us drive significant traffic growth over the last four years and has been a grade-A partner. Our decision to leave was primarily based on budget considerations (we had to reduce our agency budget) and, secondarily to get a fresh set of eyes on our strategy and tactics after a long engagement with Directive. You hear from me again and I highly recommend Directive to anyone who asks!',
            name: 'Oven Ray',
            company: 'Invoca',
        }
    ];

    useEffect(() => {
        if (prevRef.current && nextRef.current) {
            // No need to update swiper buttons here
        }
    }, []);

    return (
        <Box width="100%" maxW={["100%", "90%"]} mx="auto" position="relative" backgroundColor={["#000000"]} marginTop={[0, "80px"]} padding={[0, "50px"]}>
            <Box width={["90%", "80%"]} margin={["0 auto"]}>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    onSwiper={(swiper) => {
                        // Updating Swiper navigation after initialization
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >
                    {slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <Flex
                                direction={{ base: 'column', md: 'row' }}
                                justify="space-between"
                                align="center"
                                gap={["30px"]}
                                height={{ base: 'auto', md: '400px' }}
                                p={4}
                            >
                                {/* Left Side - Image */}
                                <Box
                                    flex={{ base: '1', md: '0.2' }} // Image takes 30% width on medium screens and up, 100% on mobile
                                    mb={{ base: 4, md: 0 }}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Image
                                        src={slide.image}
                                        alt={`Slide ${index + 1}`}
                                        width={{ base: '190px', md: '70%' }}
                                        height={{ base: '70px', md: 'auto' }}
                                        objectFit="cover"
                                    />
                                </Box>

                                {/* Right Side - Text */}
                                <Box
                                    flex={{ base: '1', md: '0.8' }} // Text takes 70% width on medium screens and up, 100% on mobile
                                    textAlign={{ base: 'center', md: 'left' }}
                                    p={4}
                                >
                                    <Box paddingBottom={["15px"]}>
                                        <img src={img2} />
                                    </Box>

                                    <Text
                                        color={["#ffffff"]}
                                        fontSize={["22px", "20px", "20px", "20px", "24px", "22px", "22px"]}
                                        fontFamily={["HouschkaPro-Medium"]}
                                        fontWeight={["400"]}
                                        lineHeight={["1.5em"]}
                                        paddingBottom={["20px"]}>
                                        {slide.title}
                                    </Text>
                                    <Text
                                        color={["#ffffff"]}
                                        fontSize={["22px", "20px", "20px", "20px", "24px", "24px", "28px"]}
                                        fontFamily={["HouschkaPro-Medium"]}
                                        lineHeight={["normal"]}
                                        fontWeight={["400"]}
                                    >
                                        {slide.name}
                                    </Text>
                                    <Text
                                        color={["#ffffff"]}
                                        fontSize={["22px", "20px", "20px", "20px", "20px", "20px", "20px"]}
                                        fontFamily={["HouschkaPro-Medium"]}
                                        lineHeight={["normal"]}
                                        fontWeight={["400"]}>
                                        {slide.company}
                                    </Text>
                                </Box>
                            </Flex>

                        </SwiperSlide>
                    ))}
                </Swiper>

                {/* Custom Left and Right Navigation Buttons */}
                <IconButton
                    ref={prevRef}
                    aria-label="Previous Slide"
                    icon={<Image src={leftArrow} alt="Left Arrow" />}
                    position="absolute"
                    top="50%"
                    left="7%"
                    transform="translateY(-50%)"
                    background="transparent"
                    _hover={{ background: 'none' }}
                    zIndex={2}
                    width={["50px"]}
                />
                <IconButton
                    ref={nextRef}
                    aria-label="Next Slide"
                    icon={<Image src={rightArrow} alt="Right Arrow" />}
                    position="absolute"
                    top="50%"
                    right="7%"
                    transform="translateY(-50%)"
                    background="transparent"
                    _hover={{ background: 'none' }}
                    zIndex={2}
                    width={["50px"]}

                />
            </Box>

        </Box>
    );
};

export default SliderComponent;
