import React from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import img from "../../../assets/LandingPage/8.svg";

const RevenueSection = () => {
    return (
        <Box paddingTop={["80px"]} paddingBottom={["80px"]} width={["80%"]} margin={["0 auto"]} backgroundColor={["#387AAA"]} borderRadius={["20px"]}>
            <Box display={["flex"]} flexDirection={["row"]} justifyContent={["space-between"]} borderRadius={["10px"]} gap={["35px"]}>

                <Box display="flex" justifyContent={["center"]} width={["85%"]} flexDirection={["column"]} margin={["0 auto"]}>
                    <Text
                        color={["#fff"]}
                        fontSize={["20px", "52px"]}
                        fontFamily={["HouschkaPro-DemiBold"]}
                        lineHeight={["normal"]}
                        textAlign={["center"]}
                        width={["75%"]}
                        margin={["0 auto"]}
                        paddingBottom={["35px"]}
                    >
                        Let’s talk about our approach to SEO for your business.
                    </Text>
                    <Box display={["flex"]} justifyContent={["center"]}>
                        <Image
                            src={img}
                            width={["75%", "75%", "25%", "25%", "22%", "22%"]} // Responsive widths for different breakpoints
                            alt="Slider Image"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default RevenueSection;