import React, { useRef, useEffect } from 'react';
import { Box, Container, Flex, Text, Image, Input, Button } from '@chakra-ui/react';
import img from "../../../assets/LandingPage/hero.webp";
import img1 from "../../../assets/LandingPage/hero-mob.webp";

const HeroSection = () => {
    const bannerText = [
        {
            title: 'Let your website take charge of the revenue growth.',
            description: "end the sentence at conversions only.",
            button: 'Get Started Now',
        },
    ];
    return (
        <Box>
            <Box display={["none", "block"]}>
                <img src={"https://d2elmmls1zw4az.cloudfront.net/girafe/landing/5.png"} />
            </Box>
            <Box display={["block", "none"]} backgroundImage={[
                `url(${img1})`,
                `url(${img1})`,
                `url(${img})`,
                `url(${img})`,
                `url(${img})`,
                `url(${img})`,
            ]}
                backgroundSize={["cover"]} backgroundRepeat={["no-repeat"]}
                backgroundPosition={["right"]}
                paddingTop={["85px", "85px", "85px", "85px", "140px", "145px"]}
                paddingBottom={["125px", "200px"]}
            >
                <Container
                    width={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    maxWidth={["90%", "90%", "90%", "90%", "90%", "90%"]}
                    marginBottom={["30px", "30px", 0, 0, 0, 0]}
                    paddingRight={["0"]}
                >
                    <Box paddingTop={["43px", "43px", "53px", "53px", "63px", "73px"]} display={["flex"]} flexDirection={["column-reverse", "column-reverse", "row", "row", "row", "row"]} gap={["50px", "50px", "100px", "100px", "100px", "100px"]}>
                        <Box width={["100%", "100%", "55%", "55%", "55%", "55%"]}>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["22px", "26px", "30px", "35px", "38px", "45px", "48px"]}
                                fontFamily={["HouschkaPro-Demibold"]}
                                lineHeight={["normal"]}
                                width={["100%", "100%", "100%", "57%", "57%", "80%"]}
                                fontWeight={["500"]}
                                textTransform={["capitalize"]}
                                paddingBottom={["10px", "10px", "10px", "20px", "20px", "20px"]}>
                                {bannerText[0].title}
                            </Text>
                            <Text
                                color={["#ffffff"]}
                                fontSize={["16px", "24px"]}
                                fontFamily={["HouschkaPro-Medium"]}
                                lineHeight={["normal"]}
                                textTransform={["capitalize"]}
                                paddingBottom={["15px", "15px", "15px", "30px", "30px", "35px"]}>
                                {bannerText[0].description}
                            </Text>
                            <Button
                                backgroundColor={["#01A6FB"]} borderRadius={["28px"]} color={["#ffffff"]} fontSize={["16px"]} fontFamily={["HouschkaPro-Medium"]} fontWeight={["500"]} paddingLeft={["40px"]} paddingRight={["40px"]} paddingTop={["20px"]} paddingBottom={["20px"]}
                            >
                                {bannerText[0].button}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};
export default HeroSection;