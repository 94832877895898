import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home"; 
import AppDevelopment from "../components/AppDevelopment/App";
import Web from "../components/WebDevelopment/Web";
import DigitalMarketing from "../components/DigitalMarketing/DigitalMarketing";
import Seo from "../components/Seo/Seo";
import LandingPage from "../components/LandingPage/LandingPage";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/app-development" element={<AppDevelopment />} />
      <Route path="/web-development" element={<Web />} />
      <Route path="/digital-marketing" element={<DigitalMarketing />} />
      <Route path="/seo" element={<Seo />} />
      <Route path="/landing" element={<LandingPage />} />
    </Routes>
  );
};

export default AllRoutes;
