import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const RevenueSection = () => {
    return (
        <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["80%"]} margin={["0 auto"]}>
            <Box>
                <Text
                    color={["#1E1E1E"]}
                    fontSize={["26px", "44px"]}
                    fontFamily={["HouschkaPro-Medium"]}
                    lineHeight={["normal"]}
                    textTransform={["capitalize"]}
                    width={["100%", "50%"]}
                    paddingBottom={["30px", "56px"]}>
                    ets Grow your revenue organically.
                </Text>
            </Box>
            <Box display={["flex"]} flexDirection={["column", "column", "column", "row", "row", "row"]} justifyContent={["space-between"]} gap={["25px", 0]}>
                <Box display="flex" alignItems="center">
                    <Box
                        width="117px"
                        height="2px" // Adjust the height as needed
                        backgroundColor="#000000" // Color of the line
                        marginRight="8px" // Space between line and text
                    />
                    <Text
                        color={["#000000"]}
                        fontSize={["20px", "24px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                        textTransform={["capitalize"]}
                    >
                        Our Methodology
                    </Text>
                </Box>

                <Box width={["100%", "50%"]}>
                    <Text
                        color={["#1e1e1e"]}
                        fontSize={["20px", "24px"]}
                        fontFamily={["HouschkaPro-Medium"]}
                        lineHeight={["normal"]}
                    >
                        We take the Customer Generation approach to SaaS content and SEO, building your content strategy around the motivations, buying signals, & research needs of your ICP.<br /><br />This is how we craft content that leads back to revenue. It not only ranks, but provides the kind of targeted value that gets emailed, Slacked, and shared with your ideal customers.
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
export default RevenueSection;