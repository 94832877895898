import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import img1 from "../../../assets/LandingPage/9.png";

const RevenueSection = () => {
    return (
        <Box>
            <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["100%"]} margin={["0 auto"]} display={["none", "flex"]}>
                <img src='https://d2elmmls1zw4az.cloudfront.net/atico1.png' width={["100%"]} />
            </Box>
            <Box paddingTop={["40px", "40px", "40px", "60px", "60px", "80px"]} width={["100%"]} margin={["0 auto"]} display={["flex", "none"]}>
                <img src={img1} width={["100%"]} />
            </Box>
        </Box>

    );
};
export default RevenueSection;